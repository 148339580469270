<template>
  <b-card :title="`${$t('Status Warehouse')} ${$t('General.Report')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Warehouse')"
                label-for="warehouse"
              >
                <v-select
                  v-model="warehouse_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="warehouse_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Warehouse')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Item')"
                label-for="item"
              >
                <v-select
                  v-model="item_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="item_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Item')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('Search') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
            <b-col md="12">
              <!-- Balance Sheet TABLE -->
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="BookOpenIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ `${$t('Status Warehouse')} ${$t('General.Report')}` }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="report_data"
                >
                  <template #cell(date)="data">
                    {{ new Date(data.value).toDateString() }}
                  </template>
                  <template #cell(due_date)="data">
                    {{ new Date(data.value).toDateString() }}
                  </template>
                  <template #cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BTable, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import reportWarehouse from '@/service/warehouse/warehouse-report'
import warehouse from '@/service/warehouse/warehouse'
import item from '@/service/warehouse/item'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationObserver,
    BCard,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BTable,
    BAlert,
    BCardHeader,
    BCardTitle,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      required,
      report_data: [],
      warehouse_id: '',
      warehouse_option: [],
      item_id: '',
      item_option: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.getReportApi()
        }
        this.switchLoc()
      })
    },
    async getReportApi() {
      this.showOverLay = true
      this.report_data = []
      await reportWarehouse.reportAxios({
        report_no: 4,
        warehouse_id: this.warehouse_id ? this.warehouse_id.id : '',
        item_id: this.item_id ? this.item_id.id : '',
      }).then(response => {
        this.report_data = response.data
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.ReportSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.ReportError'), 'danger')
      }).finally(() => { this.showOverLay = false })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await warehouse.getAxios().then(response => {
        this.warehouse_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await item.getAxios().then(response => {
        this.item_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
